import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { Container, Row, Col, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import styled from 'styled-components'
import Banner from '../../../images/blog-banner.jpg'
import YouTube from 'react-youtube';
import Blog1 from '../../../images/blog/ceo-video.jpg'
import Play from '../../../images/ceo/play.svg'
import { FaLinkedin } from 'react-icons/fa';
import BlogForm from './BlogForm'
import { FacebookIcon, FacebookShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon, LinkedinShareButton } from "react-share";






const Diversity = (props) => {

    const formReference = React.createRef();

    // const scrollToForm = () => {
    //     formReference.current.scrollIntoView({ behavior: "smooth" });
    // };

    const youTubeOpts = {
        playerVars: {
            autoplay: 1,
            modestbranding: 0,
            mute: 1,
            loop: 1,
            autohide: 1
        }
    }
    const [modal, setModal] = useState(false);
    //   const [nestedModal, setNestedModal] = useState(false);
    //   const [closeAll, setCloseAll] = useState(false);
    const toggle = () => setModal(!modal);
    const shareUrl = 'https://www.tridentconsultinginc.com/Company/Blog/Diversity/';
    const title = 'Five Ways Diverse Organizations Hire Differently';
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const { className } = props;

    return (
        <Layout path="/blog/">
            <section className="banner_min">
                <div className="banner_section">
                    {/* <Img fluid={Banner} ></Img> */}
                    <img src={Banner} alt="img" />
                </div>

            </section>

            <section className="blog_section">
                <Container>
                    <Row>
                        <Col md="12">
                            <div>
                                <h3 className="text_center">Five Ways Diverse Organizations Hire Differently  </h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md={{ size: 3, offset: 5 }}>
                            <div className="blog_heading_text">
                                <h4><span> by</span> <a href="/Company/AboutCEO">Shabana Siraj</a></h4>
                            </div>
                            <div className="blog_heading">
                                <a href="https://www.linkedin.com/in/shabanasiraj/" target="blank">
                                    <FaLinkedin size={20} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md={{ size: 3, offset: 5 }}>
                            <div className="share_blog">
                                <h5 className="share_text">Share :</h5>
                                <div className="Demo__some-network">
                                    <FacebookShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <FacebookIcon size={30} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <TwitterIcon size={30} round />
                                    </TwitterShareButton>
                                    <LinkedinShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        <LinkedinIcon size={30} round />
                                    </LinkedinShareButton>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <div className="main_card">
                                <div className="card_text">
                                    <a href onClick={toggle1}>
                                        <div className="card_button">
                                            <h4>Check out the top benefits of diversity and the statistics you need to know to build out your program.</h4>
                                        </div>
                                    </a>
                                </div>
                                <div className="card_bg">
                                    <div className="card_bg1">
                                        <a href="#" onClick={toggle1}>
                                            <div className="button">
                                                <h3>CLICK HERE</h3>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md="12">
                            <div>
                                <p>The business case for diversity is well documented in terms of how it enables companies to exceed their financial goals while successfully navigating their social responsibilities as organizations. Yet, knowing how to design and execute a diversity hiring strategy, as well as evolve it over time, to continue meeting companies’ needs, can be a challenge.</p>
                                <p>Here we share five ways diverse organizations think and act differently when it comes to hiring. In doing so, they keep diversity front and center in a way that ensures they’re meeting their hiring needs with talent that pushes the boundaries of what’s possible.</p>
                            </div>
                        </Col>
                        {/* <Col md="12">
                        </Col> */}



                        <Col md="12">
                            <h6>1. Address diversity from a broader perspective.</h6>
                            <p>Most D&I programs focus on increasing diversity by way of gender and ethnicity, and it’s for good reason. <a href="https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-wins-how-inclusion-matters" target="blank">McKinsey</a> found that gender-diverse companies outperform the least gender-diverse organizations by 48%, and ethnically-diverse organizations outperform others by 36%.</p>
                            <p>But stopping there represents a short-sighted view of diversity. Truly diverse organizations look to bring together people who are diverse in other ways than commonly defined. A <a href="https://builtin.com/diversity-inclusion/types-of-diversity-in-the-workplace" target="blank"> Built In </a> article actually identified 34 different types of diversity in the workplace. The list included more common types like age and educational background, but also others like neuro-diversity or cognitive diversity, which is defined as differences in perspective or information processing styles. An experiment run by the <a href="https://hbr.org/2017/03/teams-solve-problems-faster-when-theyre-more-cognitively-diverse" target="blank" >Harvard Business Review</a> found that cognitively diverse teams are more likely to solve problems than those that aren’t.</p>
                        </Col>
                        <Col md="12">
                            <br />
                            <div>
                                <h6>2. Cultivate a growth mindset.</h6>
                                <p>Diverse companies are often more innovative, take more risks, and look beyond the status quo to unlock their true potential. This all stems from cultivating a growth mindset culture. According to the<a href="https://neuroleadership.com/your-brain-at-work/growth-mindset-crucial-inclusion/" target="blank">NeuroLeadership Institute,</a>  having a growth mindset culture can reduce biased behavior and ultimately create stronger teams.</p>
                                <p>What does this look like during hiring? Organizations with a growth mindset continuously re-assess job descriptions. They take stock of a team’s strengths and weaknesses and identify the gaps. They access candidates from the aspect of how a candidate is different from others on the team in ways that help the entire company grow. It’s about being open to new perspectives and ideas that elevate overall performance.</p>


                                <h6>3. Rethink the concept of ’fit’. </h6>
                                <p>If companies strive for ’fit' in the traditional sense, it can potentially set them back in terms of diversity. Hiring managers often gravitate towards those with shared experiences or interests. While this can be an effective means to establish a connection, it can also lead to a one-dimensional team. If everyone is the same, there’s less debate, less out-of-the-box thinking and more risk of group think.</p>
                                <p>For diverse organizations, ‘fit’ isn’t about whether someone looks, thinks or acts just like everyone else. They assess ‘fit’ by what a candidate brings to the table. Do they have a unique perspective? Could their experience in another industry be an asset? ‘Fit’ is not about fitting in. Diverse companies think about how a candidate fits into their vision of what the company could become, based on what is currently missing.</p>

                                <h6>4. Ensure diversity at all levels.</h6>
                                <p>When you go behind the numbers, diverse talent is often hired for entry-level positions vs. those with decision-making responsibilities. While on paper this may ’check the box' on diversity totals, it does little to move the needle in terms of harnessing the innovation that comes from diversity.<a href="https://www.hrdive.com/news/microsoft-reveals-demographic-gaps-between-employees-management/567364/" target="blank">  Global organizations like Microsoft</a> have begun analyzing their organizational diversity by level, helping raise awareness of the gaps that exist higher up the ladder.</p>
                                <p>A recent <a href="https://www.gartner.com/smarterwithgartner/diversity-and-inclusion-build-high-performance-teams" target="blank"> Gartner study</a> found that by next year (2022), 75% of organizations with frontline decision-making teams reflecting a diverse and inclusive culture will exceed their financial targets. Having more diverse leadership teams will also help attract and retain diverse candidates who can envision their career path with an employer as, for example, a woman or person of color</p>


                                <h6>5. Seek out diverse partners.</h6>
                                <p>There are many ways to bring diversity into focus that can support hiring efforts. Diverse organizations often partner with other companies who share their commitment to diversity. This can include staffing firms like Trident Consulting or other workforce partners who are woman-owned, minority-owned or have publicly shared the value they place on diversity as part of their own businesses.</p>
                                <div className="blog_video_bg">
                                    <img src={Blog1} alt="img" />
                                    <a href="#" className="video-play-button" onClick={toggle}>
                                        <img src={Play} alt="img" />
                                    </a>
                                </div>
                                <Modal isOpen={modal} toggle={toggle} className="modal-dialog_video">
                                    <ModalHeader toggle={toggle}></ModalHeader>
                                    <ModalBody>
                                        <YouTube
                                            opts={youTubeOpts}
                                            videoId="W1gxesz0b1g"
                                            onPlay={true}
                                        />
                                    </ModalBody>
                                </Modal>
                                <p>Trident Consulting is a minority and woman-owned business, so we understand the importance and <a href="https://www.tridentconsultinginc.com/diversity/" target="blank">benefits of diversity </a>from the inside out. Our most successful clients use these five strategies to harness the true power of diversity, leaning on our team for support and guidance along the way.</p>
                                <p>There is still a long way to go on our collective journey to more diverse organizations. One company, one hire at a time, we can move this initiative forward in meaningful ways that make a lasting impact on businesses and the people that power them.</p>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="footer_section">
                <Container>
                    <Row>
                        <Col md="12">
                            <h1>We'd love to hear more about your staffing challenges. Reach out to our team today.</h1>
                            <div className="footer_button">
                                <a href onClick={toggle1}>Contact Us</a>
                            </div>
                            <Modal isOpen={modal1} toggle={toggle1} className={className} className="form_modal">
                                <ModalBody >
                                    <BlogForm title="We'd love to hear more about your staffing challenges. Reach out to our team today." close={() => toggle()} />
                                </ModalBody>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <BlogForm refItem = {formReference} title = "We'd love to hear more about your staffing challenges. Reach out to our team today." redirectTitle="Five Ways Diverse Organizations Hire Differently" pathname = {props.path}/> */}
        </Layout>
    )
}
const StyledBackgroundSection = styled(Diversity)`
      width: 100%;
      background-position: bottom center;
      background-repeat: repeat-y;
      background-size: cover;
      margin: 0;
      background-color: inherit !important;
      
      `

export default StyledBackgroundSection